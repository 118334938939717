<template>
  <div class="user">
    <!-- 面包屑导航 -->
    <el-card>
      <!-- 头 -->
      <!-- <el-row :gutter="20">
        <el-col :span="12">
          <div class="number">订单编号:{{ info.number }}</div>
        </el-col>
      </el-row> -->
      <el-descriptions :title="`订单编号:${info[0].order}`" :column="3" border>
        <el-descriptions-item label="桌号">{{info[0].desk}}</el-descriptions-item>
        <el-descriptions-item label="顾客昵称">{{
          info[0].user_info.nick_name
        }}</el-descriptions-item>
              <el-descriptions-item label="支付金额">
            {{ info[0].payment_amount}}
        </el-descriptions-item>
        <el-descriptions-item label="订单金额">{{
          info[0].total_prices
        }}</el-descriptions-item>
        <el-descriptions-item v-if=" info[0].state == -1" label="退款金额">
        {{
          info[0].refund_fee
        }}</el-descriptions-item>
        <el-descriptions-item label="订单状态">
                      <el-tag v-show="info[0].state == 0">订单关闭</el-tag>
            <el-tag v-show="info[0].state == 1">待支付</el-tag>
            <el-tag v-show="info[0].state == 2">支付成功</el-tag>
            <el-tag v-show="info[0].state == -1"><div style="color:red;">售后订单</div></el-tag>
        </el-descriptions-item>
  
        <el-descriptions-item label="添加时间">{{
          info[0].add_time
        }}</el-descriptions-item>
        
    </el-descriptions>
        <!-- <el-descriptions-item label="订单备注">{{
          info.note
        }}</el-descriptions-item>
      </el-descriptions> -->
      <el-descriptions :column="3" border>
        <el-descriptions-item label="商品">
          <el-table :data="info[0].sub_order_list" stripe style="width: 100%">
            <el-table-column prop="img" label="商品图片">
              <template slot-scope="scope">
                <div class="image">
                  <img
                    :src="scope.row.c_img"
                    alt=""
                    width="100"
                    v-if="scope.row.c_img"
                  />
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="c_name" label="商品名称" width="180">
            </el-table-column>
            <el-table-column prop="spec_key_name" label="商品规则" width="180">
            </el-table-column>
            <el-table-column prop="c_price" label="商品价格" width="180">
            </el-table-column>
            <el-table-column prop="num" label="商品数量">
            </el-table-column>
       
          </el-table>
        </el-descriptions-item>
      </el-descriptions>
    </el-card>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { getInfo,PostOrderFlowList } from "@/api/order.js";
import { Tag } from "element-ui";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      info: {}
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    goBack() {
      this.$router.push("/order");
    },
    //目录接口
    async catalogue() {
      console.log(this.$route.query);
      let info = await PostOrderFlowList(this.$route.query);
      this.info = info.data.data.data;
      console.log(info.data.data.data);
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.catalogue();
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
};
</script>

<style scoped>
.page {
  margin-top: 20px;
}
.title {
  font-size: 18px;
  font-weight: bold;
}
.set-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.set-page-left {
  color: #0000006d;
  font-size: 14px;
  font-weight: 400;
}
.number {
  font-size: 18px;
  color: #333;
}
.image {
  width: 100px;
  height: 100px;
  border-radius: 5px;
  display: flex;
  justify-items: center;
  align-items: center;
  background: #c0c4cc;
  overflow: hidden;
}
</style>